<template>
  <div class="d-flex flex-wrap">
    <!--         must         -->
    <ul class="dependant-list">
      <li
          :class="{'hidden-dep': isHidden(index)}"
          class="dependant dependant__must"
          v-for="(must, index) in dependantOn.must"
          :key="index"
      >
        {{ dataMap[must] }}
      </li>
    </ul>

    <!--        must not          -->
    <ul class="dependant-list">
      <li
          :class="{'hidden-dep': isHidden(index)}"
          class="dependant dependant__must-not"
          v-for="(must, index) in dependantOn.mustNot"
          :key="index"
      >
        {{ dataMap[must] }}
      </li>
    </ul>
    <!--       should         -->
    <ul class="dependant-list">
      <li
          :class="{'hidden-dep': isHidden(index)}"
          class="dependant dependant__should"
          v-for="(must, index) in dependantOn.should"
          :key="index"
      >
        {{ dataMap[must] }}
      </li>
    </ul>
    <CButton
        class="toggle-dep-button"
        v-if="isShowMoreVisible"
        variant="ghost"
        @click="toggle"
    >
      <span v-if="!isShorten">{{ $t('buttons.more') }} ...</span>
      <span v-else>{{ $t('buttons.less') }}</span>
    </CButton>

  </div>
</template>

<script>
export default {
  props: {
    dependantOn: Object,
    dataMap: Object,
  },
  data() {
    return {
      isShorten: false
    }
  },
  computed: {
    isShowMoreVisible() {
      const allDep = [...this.dependantOn.should, ...this.dependantOn.mustNot, ...this.dependantOn.must];
      return allDep.length >= 6;
    }
  },
  methods: {
    isHidden(index) {
      if (!this.isShorten) {
        return index > 1;
      } else {
        return false
      }

    },
    toggle() {
      this.isShorten = !this.isShorten
    }
  }
}
</script>

<style lang="scss">
.dependant-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0.2em 0;
  padding: 0;

  .dependant {
    position: relative;
    min-width: 180px;
    height: 32px;
    line-height: 30px;
    color: #3c4b64;
    font-weight: bold;
    font-size: 0.9em;
    outline: none;
    border-radius: 4px;
    background-color: transparent;

    margin-right: 0.4em;
    padding: 0.2em 0.7em;
    margin-bottom: 0.2rem;

    &__must {
      background: #00b83a;
      color: white;
    }

    &__must-not {
      background: #fb382b;
      color: white;
    }

    &__should {
      background: #ff8719;
      color: white;
    }
  }


  .hidden-dep {
    display: none;
  }
}

.toggle-dep-button {
  outline: none;
  span {
    font-weight: bolder;
    color: #3a99ff;
  }
}

</style>